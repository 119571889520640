import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const WarningCircleIcon: React.FC<OneColorIconProps> = ({ size = 16, color = 'red', className }) => {
  const hex = getHexColor(color)

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 14C11.8137 14 14.5 11.3137 14.5 8C14.5 4.68629 11.8137 2 8.5 2C5.18629 2 2.5 4.68629 2.5 8C2.5 11.3137 5.18629 14 8.5 14Z"
        stroke={hex}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M8.5 5V8.5" stroke={hex} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M9.1875 10.75C9.1875 11.1297 8.8797 11.4375 8.5 11.4375C8.1203 11.4375 7.8125 11.1297 7.8125 10.75C7.8125 10.3703 8.1203 10.0625 8.5 10.0625C8.8797 10.0625 9.1875 10.3703 9.1875 10.75Z"
        fill={hex}
        stroke={hex}
        strokeWidth="0.125"
      />
    </svg>
  )
}
