import React, { useMemo } from 'react'
import Link from 'next/link'
import locales from '@/constants/locales'
import { isTranslatedTheatricalPath } from '@/constants/theatricalUtils'
import { ReactFCC } from '@/types/react'
import { useLocale } from '@/utils/LocaleUtil'

export interface InternalLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string
  prefetch?: boolean
  shallow?: boolean
  scroll?: boolean
}

const localeMatcher = new RegExp(`^/(${locales.all.join('|')}).+`, 'i')

export const InternalLink: ReactFCC<InternalLinkProps> = ({
  href,
  children,
  prefetch = false,
  shallow,
  scroll,
  ...rest
}) => {
  const { locale } = useLocale()

  const linkLocale = useMemo(() => {
    if (!href) return locales.defaultLocale
    if (locales.public.find((publicLocale) => publicLocale === locale)) return locale
    if (isTranslatedTheatricalPath(href, locale)) return locale
    if (locales.beta.find((publicLocale) => publicLocale === locale)) return locale

    return locales.defaultLocale
  }, [locale, href])

  const localizedHref = useMemo(() => {
    if (!href) return null
    if (isJumpToAnchor(href) || isApiRoute(href)) return href

    const isEnglishPage = linkLocale === locales.defaultLocale
    if (isEnglishPage) return href

    const isHrefRelativePath = href.startsWith('/')
    const doesHrefIncludeLocale = localeMatcher.test(href)

    if (isHrefRelativePath && !doesHrefIncludeLocale) {
      return `/${linkLocale}${href}`
    }

    return href
  }, [linkLocale, href])

  // NOTE: href should always be a string, but likely due to poor type casting upstream in production we sometimes receive a falsey href
  if (!href || !localizedHref) return null

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const anchorProps: any = { ...rest }
  if (anchorProps.linkContext) delete anchorProps.linkContext

  if (isApiRoute(href) || isJumpToAnchor(href)) {
    return (
      <a href={href} {...anchorProps}>
        {children}
      </a>
    )
  }

  return (
    // prefetch={true} is deprecated so set to undefined if true https://nextjs.org/docs/messages/prefetch-true-deprecated
    <Link
      href={localizedHref}
      prefetch={prefetch ? undefined : false}
      shallow={shallow}
      passHref
      legacyBehavior
      scroll={scroll}
    >
      <a {...anchorProps}>{children}</a>
    </Link>
  )
}

function isJumpToAnchor(href: string) {
  return !!href && href.startsWith('#')
}

function isApiRoute(href: string) {
  return !!href && href.startsWith('/api')
}
