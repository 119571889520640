import React, { useMemo } from 'react'
import { ReactFCC } from '@/types/react'

export interface ExternalLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string
  target?: string
  trusted?: boolean
}

export const TRUSTED_DOMAINS = ['angel.com', 'angelstudios.com']

const isTrustedDomain = (href: string): boolean => {
  if (!href) return false
  return href.startsWith('/') || TRUSTED_DOMAINS.some((domain) => href.indexOf(domain) > -1)
}

export const ExternalLink: ReactFCC<ExternalLinkProps> = ({ href, trusted, target, children, ...rest }) => {
  const isTrusted = useMemo(() => trusted || isTrustedDomain(href), [href, trusted])
  if (!href) return null

  const isAnApiRoute = href.startsWith('/api')
  const targ = target ? target : isAnApiRoute ? '_self' : '_blank'

  return (
    <a href={href} target={targ} rel={`${isTrusted ? '' : 'external noopener noreferrer'}`} {...rest}>
      {children}
    </a>
  )
}
