import pick from 'lodash/pick'
import { useTranslation as useTranslationNext } from 'next-i18next'
import { useRouter } from 'next/router'
import type { UseTranslationResponse } from 'react-i18next'
import { useLocale } from '@/utils/LocaleUtil'
import { logger } from '@/utils/logging'
import { isDevelopmentEnvironment } from '../environment-utils'

export type TranslationNamespace =
  | 'about'
  | 'account'
  | 'ads'
  | 'ambassador'
  | 'app-promo'
  | 'category'
  | 'chatbot'
  | 'collectibles'
  | 'collectibles-marketplace'
  | 'coming-soon'
  | 'common'
  | 'create'
  | 'early-access'
  | 'features'
  | 'filmmaker'
  | 'guild'
  | 'guild-benefits'
  | 'guild-create'
  | 'guild-merch'
  | 'guild-voting'
  | 'marketing-gift'
  | 'home'
  | 'impact'
  | 'join-the-guild'
  | 'livestream'
  | 'notification-preferences'
  | 'partnership'
  | 'pif'
  | 'pt-announcement'
  | 'redeem'
  | 'sign-up'
  | 'sight-announcement'
  | 'theatrical-presales'
  | 'theatrical-exclusive-scenes'
  | 'the-shift-exclusive'
  | 'tickets'
  | 'torches'
  | 'viewer-profiles'
  | 'watch'
  | 'world-tour'

export function useTranslateInternal(namespace: TranslationNamespace) {
  // Note, renaming useTranslation to useTranslationNext intentionally makes it so the i18n-parser won't parse this file.
  // By design, the i18n-parser will throw a warning, which breaks our build, when a variable is provided.
  // In this case, we really do want to pass in the namespace as a variable, because we've created the wrapper function.
  const result = useTranslationNext(namespace)
  const { pathname } = useRouter()
  const { locale } = useLocale()

  if (locale && result && result.ready) {
    const b = result.i18n?.getResourceBundle?.(locale, namespace)
    if (!b) {
      const currentPath = pathname.split('?')[0]
      const message = `The locale "${locale}" for translations for this namespace "${namespace}" has not been loaded! Verify that this namespace is being loaded using the "loadTranslations" function for this page: "${currentPath}".`
      if (isDevelopmentEnvironment()) throw new Error(message)
      else
        logger().error(message, {
          namespace,
          locale,
          currentPath,
          result: formatResultForLogs(result),
        })
    }
  }

  return result
}

function formatResultForLogs(result: UseTranslationResponse<TranslationNamespace, undefined>) {
  const { ready, i18n } = result
  const i18nCopy = pick(i18n, ['language', 'languages', 'resolvedLanguage', 'isInitialized'])
  const optionsCopy = pick(i18n.options, [
    'debug',
    'partialBundledLanguages',
    'lng',
    'fallbackLng',
    'supportedLngs',
    'nonExplicitSupportedLngs',
    'load',
    'preload',
    'lowerCaseLng',
    'cleanCode',
    'ns',
    'defaultNS',
    'fallbackNS',
    'saveMissing',
    'saveMissingTo',
    'saveMissingPlurals',
    'updatingMissing',
    'missingKeyNoValueFallbackToKey',
    'appendNamespaceToMissingKey',
    'simplifyPluralSuffix',
    'postProcess',
    'postProcessPassResolved',
    'returnNull',
    'returnEmptyString',
    'returnObjects',
    'returnDetails',
    'joinArrays',
    'interpolation',
    'initImmediate',
    'keySeparator',
    'nsSeparator',
    'pluralSeparator',
    'contextSeparator',
    'appendNamespaceToCIMode',
    'compatibilityJSON',
    'locizeLastUsed',
    'ignoreJSONStructure',
    'maxParallelReads',
    'maxRetries',
    'retryTimeout',
  ])

  return { ready, i18n: { ...i18nCopy, options: optionsCopy } }
}
