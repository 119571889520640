import { useCallback, useMemo } from 'react'
import { logger } from '@/utils/logging'

interface MobileShareProps {
  shareUrl: string
  title?: string
  message?: string
  onShareFail?: () => void
}

export const useNativeMobileShare = () => {
  const share = useCallback(async ({ shareUrl, title, message, onShareFail }: MobileShareProps) => {
    const data = {
      title,
      text: message,
      shareUrl,
    }
    if (navigator.share && navigator.canShare(data)) {
      try {
        await navigator.share(data)

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error?.name !== 'AbortError') {
          if (onShareFail) onShareFail()
          logger().error(`An error occurred sharing ${shareUrl}`, error)
        }
      }
    } else {
      if (onShareFail) onShareFail()
    }
  }, [])

  return useMemo(() => {
    return { share }
  }, [share])
}
