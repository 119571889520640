import React from 'react'
import classNames from 'classnames'
import { When } from 'react-if'
import { ExternalLink } from '@/atoms/ExternalLink'
import { InternalLink } from '@/atoms/InternalLink'
import { AngelLogo } from '@/atoms/Logos/AngelLogo'
import { ParagraphMD } from '@/atoms/Text'
import { paths } from '@/constants/paths'
import { useExperimentContext } from '@/experimentation'
import { NotificationsMenu } from '@/molecules/Notifications'
import { RegionSelector } from '@/molecules/RegionSelector'
import { OmniboxSearchInput } from '@/organisms/Search'
import { MobileNav } from '@/organisms/SiteNav/MobileSiteNav/MobileNav'
import { useSiteNavContext } from '@/organisms/SiteNav/SiteNavContext'
import { UserMenu } from '@/organisms/SiteNav/UserMenu'
import { useUser } from '@/services/UserService'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

export interface MobileSiteNavProps {
  isGuildMember?: boolean
}

export const MobileSiteNav: React.FC<MobileSiteNavProps> = ({ isGuildMember = false }) => {
  const { t } = useTranslate('common')

  const { getFeatureValue } = useExperimentContext()
  const shouldRedirectToTicketsPage = getFeatureValue('site_nav_new_releases', false)

  const {
    closeMobileMenu,
    fetchOnlyNew,
    handleCloseNavMenu,
    handleOpenLanguageModal,
    handleOpenNotificationMenu,
    handleOpenRegionMenu,
    handleOpenRegionModal,
    handleCloseLanguageModal,
    hideMobileNavigation,
    hideNavigation,
    isMobileMenuOpen,
    isNotificationsMenuOpen,
    isRegionsMenuOpen,
    languagesEnabled,
    notificationsCount,
    regions,
    shouldUseDarkMode,
    showLanguageAndHelpLinks,
    showNotifications,
    toggleMobileMenu,
  } = useSiteNavContext()

  return (
    <div
      className={classNames(
        'flex lg:hidden flex-row justify-between items-center z-50 py-3 px-4 h-16',
        hideMobileNavigation && 'hidden',
      )}
    >
      {/* Navigation */}
      <When condition={!hideNavigation}>
        <div className="flex w-32 flex-row items-center justify-start gap-1">
          <div className={classNames('relative w-8 h-6')}>
            <input
              type="checkbox"
              id="mobile-menu-toggle"
              className="hidden"
              checked={isMobileMenuOpen}
              onChange={toggleMobileMenu}
            />
            <label
              htmlFor="mobile-menu-toggle"
              className={classNames('icon cursor-pointer', shouldUseDarkMode ? 'icon-dark' : 'icon-light')}
            >
              <span />
            </label>

            <label
              htmlFor="mobile-menu-toggle"
              className={classNames(
                'overlay -z-40  w-screen fixed top-0 left-0 bottom-0 right-0 transition-height duration-300',
                shouldUseDarkMode ? 'bg-core-gray-950' : 'bg-white',
                isMobileMenuOpen ? 'h-screen' : 'h-0',
              )}
            />

            <nav
              aria-labelledby="mobile-nav-title"
              className={classNames(
                'relative z-50 -ml-4 mt-2 w-screen overflow-y-scroll lg:overflow-y-auto overflow-x-hidden transition-height duration-200',
                isMobileMenuOpen ? 'h-[95vh]' : 'h-0',
              )}
            >
              <h2 id="mobile-nav-title" className="sr-only">
                <Translate t={t} i18nKey="mobileMenu">
                  Mobile Menu
                </Translate>
              </h2>
              <MobileNav.Modal>
                <MobileNav.Menu
                  label={t('menuLinkWatch', 'Watch')}
                  href={paths.watch.index}
                  Element={InternalLink}
                  onClick={closeMobileMenu}
                  id="watch-menu"
                >
                  <MobileNav.Item
                    Element={InternalLink}
                    label={t('christian', 'Christian')}
                    href={paths.category.christianMovies}
                    id="christian-category"
                    onClick={closeMobileMenu}
                    linkContext="menu-link"
                  />
                  <MobileNav.Item
                    Element={InternalLink}
                    label={t('comedy', 'Comedy')}
                    href={paths.category.comedyTvShows}
                    id="comedy-category"
                    onClick={closeMobileMenu}
                    linkContext="menu-link"
                  />
                  <MobileNav.Item
                    Element={InternalLink}
                    label={t('drama', 'Drama')}
                    href={paths.category.drama}
                    id="drama-category"
                    onClick={closeMobileMenu}
                    linkContext="menu-link"
                  />
                  <MobileNav.Item
                    Element={InternalLink}
                    label={t('documentary', 'Documentary')}
                    href={paths.category.documentaries}
                    id="documentary-category"
                    onClick={closeMobileMenu}
                    linkContext="menu-link"
                  />
                  <MobileNav.Item
                    Element={InternalLink}
                    label={t('family', 'Family')}
                    href={paths.category.family}
                    id="family-category"
                    onClick={closeMobileMenu}
                    linkContext="menu-link"
                  />
                  <MobileNav.Item
                    Element={InternalLink}
                    label={t('free', 'Free')}
                    href={paths.category.free}
                    id="free-category"
                    onClick={closeMobileMenu}
                    linkContext="menu-link"
                  />
                </MobileNav.Menu>
                <MobileNav.Menu
                  label={t('menuLinkNewReleases', 'New Releases')}
                  href={shouldRedirectToTicketsPage ? paths.tickets.index : paths.comingSoon.index}
                  Element={InternalLink}
                  onClick={closeMobileMenu}
                  id="new-releases-menu"
                >
                  <MobileNav.Item
                    Element={InternalLink}
                    label={t('inTheaters', 'In Theaters')}
                    href={paths.tickets.index}
                    onClick={closeMobileMenu}
                    id="in-theaters"
                  />
                  <MobileNav.Item
                    Element={InternalLink}
                    label={t('comingSoon', 'Coming Soon')}
                    href={paths.comingSoon.index}
                    onClick={closeMobileMenu}
                    id="coming-soon"
                  />
                </MobileNav.Menu>
                <MobileNav.Menu
                  label={t('menuLinkGuild', 'Guild')}
                  href={
                    isGuildMember
                      ? `${paths.guild.index}?utm_medium=web&utm_campaign=guild-memberships&utm_source=sitenav`
                      : `${paths.guild.join}?utm_medium=web&utm_campaign=guild-memberships&utm_source=sitenav`
                  }
                  Element={InternalLink}
                  onClick={closeMobileMenu}
                  id="guild-menu"
                >
                  <MobileNav.Item
                    Element={InternalLink}
                    label={isGuildMember ? t('myBenefits', 'My Benefits') : t('learnMore', 'Learn More')}
                    href={
                      isGuildMember
                        ? `${paths.guild.index}?utm_medium=web&utm_campaign=guild-memberships&utm_source=sitenav`
                        : `${paths.guild.join}?utm_medium=web&utm_campaign=guild-memberships&utm_source=sitenav`
                    }
                    onClick={closeMobileMenu}
                    linkContext="menu-link"
                    id="guild-dashboard-join"
                  />
                  <MobileNav.Item
                    Element={InternalLink}
                    label={t('menuLinkSubmitYourProject', 'Submit Your Project')}
                    href={paths.filmmakers.index}
                    onClick={closeMobileMenu}
                    id="submit-your-project"
                  />
                  <MobileNav.Item
                    Element={InternalLink}
                    label={t('angelImpact', 'Angel Impact')}
                    href={paths.guild.impact}
                    onClick={closeMobileMenu}
                    id="guild-impact"
                  />
                  <MobileNav.Item
                    Element={InternalLink}
                    label={t('guildVoting', 'Guild Voting')}
                    href={paths.guild.voting}
                    onClick={closeMobileMenu}
                    id="cast-your-vote"
                  />
                  <MobileNav.Item
                    Element={InternalLink}
                    label={t('guildGift', 'Gift the Guild')}
                    href={paths.guild.gift}
                    onClick={closeMobileMenu}
                    id="gift-the-guild"
                  />
                </MobileNav.Menu>
                <MobileNav.Item
                  Element={ExternalLink}
                  label={t('menuLinkShop', 'Shop')}
                  href={paths.external.shop.index}
                  id="shop"
                />
                <MobileNav.Item Element={InternalLink} label={t('blog', 'Blog')} href={paths.blog.index} id="blog" />
                <hr
                  className={classNames('mx-2 my-1.5', shouldUseDarkMode ? 'border-t-gray-800' : 'border-gray-200')}
                />
                {!showLanguageAndHelpLinks && (
                  <>
                    {languagesEnabled && (
                      <div
                        onClick={() => {
                          handleOpenLanguageModal()
                          closeMobileMenu()
                        }}
                        className={classNames(
                          'whitespace-nowrap block',
                          'rounded-md px-2 py-1.5 cursor-pointer bg-transparent transition-bg duration-200',
                          shouldUseDarkMode ? 'hover:bg-[rgb(255,255,255,0.1)]' : 'hover:bg-[rgb(0,0,0,0.06)]',
                        )}
                      >
                        <ParagraphMD color={shouldUseDarkMode ? 'white' : 'black'}>
                          {t('languages', 'Languages')}
                        </ParagraphMD>
                      </div>
                    )}
                    <MobileNav.Item
                      Element={ExternalLink}
                      label={t('menuLinkHelp', 'Help')}
                      href={paths.external.zendesk.support}
                      onClick={closeMobileMenu}
                      id="help"
                    />
                  </>
                )}
              </MobileNav.Modal>
            </nav>
          </div>
          <OmniboxSearchInput />
          <div>
            {regions && regions?.length > 0 && (
              <RegionSelector
                onRegionModalOpen={handleOpenRegionModal}
                onLanguageModalClose={handleCloseLanguageModal}
                closeMobileMenu={closeMobileMenu}
                isMobileNavItem
                onClose={handleCloseNavMenu}
                onOpen={handleOpenRegionMenu}
                isOpen={isRegionsMenuOpen}
                isDarkMode={shouldUseDarkMode}
                regions={regions}
              />
            )}
          </div>
        </div>
      </When>

      {/* Angel Logo */}
      <div className="flex shrink-0 items-center">
        <LogoLink closeMobileMenu={closeMobileMenu} shouldUseDarkMode={shouldUseDarkMode} />
      </div>

      {/* User Nav */}
      <div className="flex w-32 flex-row items-center justify-end">
        {showNotifications && (
          <NotificationsMenu
            notificationsCount={notificationsCount}
            fetchOnlyNew={fetchOnlyNew}
            isOpen={isNotificationsMenuOpen}
            onClose={handleCloseNavMenu}
            onOpen={handleOpenNotificationMenu}
            isDarkMode={shouldUseDarkMode}
          />
        )}

        <UserMenu onClick={closeMobileMenu} loginButtonVariant={shouldUseDarkMode ? 'white' : 'black'} />
      </div>
    </div>
  )
}

function LogoLink(props: { shouldUseDarkMode: boolean; closeMobileMenu: () => void }) {
  const { isLoggedIn } = useUser()

  return (
    <InternalLink
      aria-label="Angel Studios"
      href={isLoggedIn ? paths.watch.index : paths.home}
      onClick={props.closeMobileMenu}
    >
      <When condition={props.shouldUseDarkMode}>
        <AngelLogo color="white" />
      </When>
      <When condition={!props.shouldUseDarkMode}>
        <AngelLogo color="black" />
      </When>
    </InternalLink>
  )
}
